import request from '@/utils/request'

//图表数据接口
export function loadEcharts() {
  return request({
    url: '/get_home_echarts',
    method: 'post',
  })
}

// 首页-新
export function homeEcharts() {
  return request({
    url: '/home_echarts2',
    method: 'post',
  })
}
